import {fetchState} from "./callbotService";

export function getCurrentCallId() {
  return window.callbotTestClient.calls[window.callbotTestClient.calls.length - 1].callId;
}

export function addCall(callId, start) {
  window.callbotTestClient.calls.push({
    callId,
    socketMessages: [],
  });
}

export async function getCallState(callId) {
  if (!callId) {
    callId = getCurrentCallId();
  }
  const response = await fetchState(callId);
  if (response.ok || response.status === 400) {
    return await response.json();
  }
  return null;
}


export function init() {
  window.callbotTestClient = {
    calls: [],
    state: getCallState,
  };
}

export async function addSocketMessage(message) {
  const state = await getCallState();
  window.callbotTestClient.calls[window.callbotTestClient.calls.length - 1].socketMessages.push(
    {message, state});
}

export function cleanup() {
  delete window.callbotTestClient;
}

